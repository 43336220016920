<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col c cols="12" sm="12" md="12">
          <h2>Father Info...</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="FLiDe"
            label="Living or Deceased?"
            dense
            outlined
            :items="LiDeOpt"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FName"
            :rules="[vv.init(this.FName), vv.required()]"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FContact"
            :rules="[vv.init(this.FContact), vv.required()]"
            label="Contact No."
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FNationality"
            :rules="[vv.init(this.FNationality), vv.required()]"
            label="Nationality"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FEduc"
            :rules="[vv.init(this.FEduc), vv.required()]"
            label="Educational Attainment"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FOccupation"
            :rules="[vv.init(this.FOccupation), vv.required()]"
            label="Occupation/Position"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="FType"
            label="Blue Collar/White Collar"
            dense
            outlined
            :items="WorkType"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FEmployer"
            label="Employer/Agency"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="FAbroad"
            label="Working Abroad?"
            dense
            outlined
            :items="AbroadOpt"
          ></v-select>
        </v-col>

        <v-col c cols="12" sm="12" md="12">
          <h2>Mother Info...</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="MLiDe"
            label="Living or Deceased?"
            dense
            outlined
            :items="LiDeOpt"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MName"
            :rules="[vv.init(this.MName), vv.required()]"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MContact"
            :rules="[vv.init(this.MContact), vv.required()]"
            label="Contact No."
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MNationality"
            :rules="[vv.init(this.MNationality), vv.required()]"
            label="Nationality"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MEduc"
            :rules="[vv.init(this.MEduc), vv.required()]"
            label="Educational Attainment"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MOccupation"
            :rules="[vv.init(this.MOccupation), vv.required()]"
            label="Occupation/Position"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="MType"
            label="Blue Collar/White Collar"
            dense
            outlined
            :items="WorkType"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MEmployer"
            label="Employer/Agency"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="MAbroad"
            label="Working Abroad?"
            dense
            outlined
            :items="AbroadOpt"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Marital Status of Parents:</h2>
          <v-divider></v-divider>
          <v-radio-group v-model="MaritalSts" row>
            <v-radio label="Living Together" value="Living Together"></v-radio>
            <v-radio label="Separated" value="Separated"></v-radio>
            <v-radio label="Annulled" value="Annulled"></v-radio>
            <v-radio label="Widowed" value="Widowed"></v-radio>
            <v-radio
              label="Father w/ another partner"
              value="Father w/ another partener"
            ></v-radio>
            <v-radio
              label="Mother w/ another partner"
              value="Mother w/ another partener"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Estimated Monthly Income of Parents:</h2>
          <v-divider></v-divider>
          <v-radio-group v-model="EstMIncome" row>
            <v-radio label="Below P5,000" value="Below P5,000"></v-radio>
            <v-radio
              label="P5,001 to P10,000"
              value="P5,001 to P10,000"
            ></v-radio>
            <v-radio
              label="P10,001 to P15,000"
              value="P10,001 to P15,000"
            ></v-radio>
            <v-radio
              label="P15,001 to P20,000"
              value="P15,001 to P20,000"
            ></v-radio>
            <v-radio
              label="P20,001 to P25,000"
              value="P20,001 to P25,000"
            ></v-radio>
            <v-radio
              label="P25,001 and above"
              value="P25,001 and above"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="NoChild"
            label="Number of Children in the family"
            outlined
            dense
            :rules="[vv.init(this.NoChild), vv.requiredNumber()]"
            required
            type="number"
            min="1"
            @change="setBirthOrder()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            v-model="BirthOrder"
            :items="BirthOrderOpt"
            item-text="name"
            label="Student's birth order in the family"
            outlined
            dense
            :rules="[vv.init(this.Dialects), vv.requiredSelect()]"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="Dialects"
            label="What languages and dialect do you speak at home?"
            outlined
            dense
            :rules="[vv.init(this.Dialects), vv.required()]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="savechanges"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Your changes have been successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import vldn from "@/js/validation";
export default {
  name: "Parents",
  data: () => ({
    snackbar: false,
    timeout: 2000,
    valid: true,
    WorkType: ["Blue Collar", "White Collar"],
    AbroadOpt: ["YES", "NO"],
    LiDeOpt: [
      { value: 0, text: "Deceased" },
      { value: 1, text: "Living" },
    ],
    BirthOrderOpt: [],
    FLiDe: 1,
    FName: "",
    FContact: "",
    FNationality: "",
    FEduc: "",
    FOccupation: "",
    FEmployer: "",
    FAbroad: "NO",
    MLiDe: 1,
    MName: "",
    MContact: "",
    MNationality: "",
    MEduc: "",
    MOccupation: "",
    MEmployer: "",
    MAbroad: "NO",
    MaritalSts: "Living Together",
    EstMIncome: "P5,001 to P10,000",
    NoChild: 1,
    BirthOrder: "",
    Dialects: "",
    FType: "White Collar",
    MType: "White Collar",
    vv: vldn.methods,
  }),
  mounted() {
    this.$api.methods.init();
    this.getParent();
    this.setBirthOrder();
  },
  methods: {
    setBirthOrder() {
      this.BirthOrderOpt = [];
      if (this.NoChild) {
        for (var i = 1; i <= this.NoChild; i++) {
          this.BirthOrderOpt.push(this.ordinal_suffix_of(i));
        }
      }
      return true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return { name: i + "st", value: i.toString() };
      }
      if (j == 2 && k != 12) {
        return { name: i + "nd", value: i.toString() };
      }
      if (j == 3 && k != 13) {
        return { name: i + "rd", value: i.toString() };
      }

      return { name: i + "th", value: i.toString() };
    },

    getParent() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/parent",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            if (response.data.length > 0) {
              this.FLiDe = response.data[0].FLiDe;
              this.FName = response.data[0].FName;
              this.FContact = response.data[0].FContact;
              this.FNationality = response.data[0].FNationality;
              this.FEduc = response.data[0].FEduc;
              this.FOccupation = response.data[0].FOccupation;
              this.FEmployer = response.data[0].FEmployer;
              this.FAbroad = response.data[0].FAbroad;
              this.MLiDe = response.data[0].MLiDe;
              this.MName = response.data[0].MName;
              this.MContact = response.data[0].MContact;
              this.MNationality = response.data[0].MNationality;
              this.MEduc = response.data[0].MEduc;
              this.MOccupation = response.data[0].MOccupation;
              this.MEmployer = response.data[0].MEmployer;
              this.MAbroad = response.data[0].MAbroad;
              this.MaritalSts = response.data[0].MaritalSts;
              this.EstMIncome = response.data[0].EstMIncome;
              this.NoChild = response.data[0].NoChild;
              if (this.setBirthOrder()) {
                this.BirthOrder = response.data[0].BirthOrder;
              }

              this.Dialects = response.data[0].Dialects;
              this.FType = response.data[0].FType;
              this.MType = response.data[0].MType;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "guid/updateparent",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            FLiDe: this.FLiDe,
            FName: this.FName,
            FContact: this.FContact,
            FNationality: this.FNationality,
            FEduc: this.FEduc,
            FOccupation: this.FOccupation,
            FEmployer: this.FEmployer,
            FAbroad: this.FAbroad,
            MLiDe: this.MLiDe,
            MName: this.MName,
            MContact: this.MContact,
            MNationality: this.MNationality,
            MEduc: this.MEduc,
            MOccupation: this.MOccupation,
            MEmployer: this.MEmployer,
            MAbroad: this.MAbroad,
            MaritalSts: this.MaritalSts,
            EstMIncome: this.EstMIncome,
            NoChild: this.NoChild,
            BirthOrder: this.BirthOrder,
            Dialects: this.Dialects,
            FType: this.FType,
            MType: this.MType,
          },
        })
          .then((response) => {
            if (response) {
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>

<style>
.container {
  max-width: 80%;
}
</style>